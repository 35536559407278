<template>
  <div style="min-height: 100%; background-color: #1e1e1e">
    <v-row no-gutters>
      <v-col
        v-if="maturities.length"
        cols="12"
      >
        <v-sheet
          width="100%"
          class="py-12 px-8"
        >
          <v-row no-gutters>
            <v-col
              xs="0"
              sm="0"
              md="0"
              lg="1"
              xl="1"
            >
            </v-col>

            <v-col
              xs="12"
              sm="12"
              md="12"
              lg="10"
              xl="10"
              class="d-flex flex-column"
            >
              <v-card outlined style="height: 75vh;">
                <v-chart class="chart" autoresize :option="sparkline" />
              </v-card>
            </v-col>

            <v-col
              xs="0"
              sm="0"
              md="0"
              lg="1"
              xl="1"
            >
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>

      <v-col 
        cols="12"
      >
        <v-sheet
          width="100%"
          class="py-12 px-8"
        >
          <v-row no-gutters>
            <v-col
              xs="0"
              sm="0"
              md="0"
              lg="1"
              xl="1"
            >
            </v-col>

            <v-col
              xs="12"
              sm="12"
              md="12"
              lg="10"
              xl="10"
              class="d-flex flex-column"
            >
              <v-card outlined style="height: 75vh;">
                <div class="d-flex mx-4 my-4">
                  <v-file-input 
                    dense 
                    v-model="maturitiesCsvModel"
                    hide-details
                    :label="$t('cashFlow.inputPlaceHolder')"
                    :placeholder="$t('cashFlow.inputPlaceHolder')"
                    @change="changeFile($event)"
                    :value="maturitiesCsv"
                    single-line
                    color="primary"
                    prepend-icon="mdi-calendar"
                    accept="txt/*,.csv"
                    style="width: 350px; max-width: 350px;"
                    width="350"
                    class="mb-4 mr-4"
                    :disabled="loadingPut || loadingGet"
                  > 
                  </v-file-input>
                  
                  <v-btn 
                    outlined 
                    color="primary" 
                    small
                    :loading="loadingPut"
                    :disabled="loadingGet || !maturitiesCsvModel"
                    @click="showDialog()"
                  >
                    {{ $t('cashFlow.import') }}
                  </v-btn>

                  <div class="ml-4">
                    <v-btn 
                      outlined 
                      color="primary" 
                      small
                      :loading="loadingDownload"
                      :disabled="loadingGet || loadingPut || !maturities.length"
                      @click="exportCurrencyMaturities()"
                    >
                    {{ $t('cashFlow.export') }}
                    </v-btn>
                  </div>
                </div>

                <v-data-table 
                  v-if="maturities.length"
                  style="max-height: calc(75vh - 87px); overflow-y: auto;"
                  item-key="maturity"
                  :headers="headers"
                  :items="maturities"
                  hide-default-footer
                  disable-pagination
                  dense
                >
                  <template slot="item" slot-scope="props">
                    <tr>
                      <td class="text-center accent--text">
                        {{ props.item["Due date"] }}
                      </td>
                      <td class="text-center alive--text">
                        {{ props.item.Income }}
                      </td>
                      <td class="text-center attention--text">
                        {{ props.item.Expenses }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>

                <div v-else class="d-flex justify-center mb-10 mt-2">
                  <span class="text-h5">Import a file to see the maturities table and chart.</span>
                </div>
              </v-card>
            </v-col>

            <v-col
              xs="0"
              sm="0"
              md="0"
              lg="1"
              xl="1"
            >
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialog"
      width="400"
      content-class="border-dialog"
      persistent
    >
      <v-card>
        <v-card-title>
          <!--
          <div v-if="loadingPut" class="headline orange--text">
            Importing curve
          </div>
          -->

          <!-- v-if="!loadingPut && uploadSuccess === undefined" -->
          <div class="headline primary--text">
            Aviso
          </div>
          <!--
          <div v-if="!loadingPut && uploadSuccess === false" class="headline orange--text">
            Fail to import
          </div>

          <div v-if="!loadingPut && uploadSuccess" class="headline orange--text">
            Imported with success
          </div>    
          -->      
        </v-card-title>

        <!-- v-if="!loadingPut && uploadSuccess === undefined" -->
        <v-card-text>
          <div style="display: flex; flex-direction: column">
            Este processo sobrescreve todo o banco de dados Hedge na plataforma.
          </div>
        </v-card-text>

        <v-card-text v-if="loadingPut" class="d-flex justify-center">
          <loading-custom></loading-custom>
        </v-card-text>

        <v-card-text v-if="uploadSuccess" class="d-flex justify-center">
          <v-icon
            style="font-size: 110px"
          >
            mdi-check
          </v-icon>
        </v-card-text>

        <v-card-text v-if="uploadSuccess === false" class="d-flex justify-center">
          <v-icon
            style="font-size: 110px"
          >
            mdi-close
          </v-icon>
        </v-card-text>

        <v-divider v-if="!loadingPut && uploadSuccess === undefined"/>

        <v-card-actions v-if="!loadingPut && uploadSuccess === undefined">
          <v-spacer/>
          <v-btn
            text
            @click="csvFile = undefined, dialog = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="importMaturities();"
          >
            Confirmar
          </v-btn>
        </v-card-actions>

        <v-divider v-if="!loadingPut && uploadSuccess !== undefined"/>

        <v-card-actions v-if="!loadingPut && uploadSuccess !== undefined">
          <v-spacer/>
          <v-btn
            color="primary"
            text
            @click="dialog = false; uploadSuccess=undefined;"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar 
      v-model="snackbarError" 
      :timeout="7000" 
      bottom
      color="error"
    >
      {{ errorMsg }}

      <template v-slot:action="{ attrs }">
        <v-btn 
          text 
          v-bind="attrs"
          @click="snackbarError = false"
        > 
          OK
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar 
      v-model="snackbarSuccess" 
      :timeout="4000" 
      bottom
      color="alive"
    >
      Imported with success

      <template v-slot:action="{ attrs }">
        <v-btn 
          text 
          v-bind="attrs"
          @click="snackbarSuccess = false"
        > 
          OK
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
// import { mapState, mapWritableState, mapActions } from "pinia"
import { mapActions } from "pinia"
import { useCashflowStore } from "./../store/index.js"
import LoadingCustom from './../components/LoadingCustom'
import VChart from "vue-echarts";
import { use } from 'echarts/core'
import { BarChart } from 'echarts/charts'
import { TooltipComponent, GridComponent } from 'echarts/components'
import { CanvasRenderer } from 'echarts/renderers'
import colors from "vuetify/lib/util/colors"

use([
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer
])

export default {
  components: {
    LoadingCustom,
    VChart,
  },
  data () {
    return {
      headers: [
        {
          text: this.$t('cashFlow.table.deadline'),
          value: "maturity",
          sortable: false,
          align: "center",
          width: "150px"
        },
        {
          text: this.$t('cashFlow.table.moneyOut'),
          value: "Income",
          sortable: false,
          align: "center",
          width: "150px"
        },
        {
          text: this.$t('cashFlow.table.moneyIn'),
          value: "Expenses",
          sortable: false,
          align: "center",
          width: "150px"
        },
      ],
      itemTable: {
        maturity: [
          "2024-29-11",
          "2024-28-11",
          "2024-27-11",
          "2024-26-11",
          "2024-25-11",
          "2024-22-11",
          "2024-21-11",
          "2024-20-11",
          "2024-19-11",
          "2024-18-11"
        ],
        bid: [
          6.085,
          6.083,
          6.081,
          6.079,
          6.072,
          6.011,
          6.069,
          6.095,
          6.118,
          6.058
        ],
        ask: [
          5.885,
          5.883,
          5.881,
          5.879,
          5.872,
          5.811,
          5.869,
          5.895,
          5.918,
          5.858
        ]
      },
      maturitiesCsvModel: undefined,
      loadingDownload: false,
      snackbarError: false,
      snackbarSuccess: false,
      errorMsg: "",
      maturities: [],
      maturitiesCsv: undefined,
      loadingPut: false,
      loadingGet: false,
      dialog: false,
      uploadSuccess: undefined,
    }
  },
  computed: {
    sparkline () {
      const lastTenMaturities = this.maturities

      return {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
          formatter: (params) => {
            const dataIndex = params[0].dataIndex;
            const item = lastTenMaturities[dataIndex];
            const [year, month, day] = item["Due date"].split('-');
            const formattedDate = `${day}/${month}/${year}`;
            return `
              <strong>Date:</strong> ${formattedDate}<br />
              <strong>Rate Bid (Up):</strong> ${item.Income}<br />
              <strong>Rate Ask (Down):</strong> ${item.Expenses}<br />
            `;
          },
        },
        xAxis: {
          type: 'category',
          data: lastTenMaturities.map(maturity => maturity["Due date"]),
          axisLabel: {
            color: '#ffffff',
            formatter: (value) => {
              const [year, month, day] = value.split('-');
              return `${day}/${month}/${year}`;
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: '#ffffff'
          },
          splitLine: {
            lineStyle: {
              color: '#444'
            }
          }
        },
        series: [
          {
            name: 'Rate Bid (Up)',
            data: lastTenMaturities.map(maturity => 
              parseFloat(maturity.Income.replace(/,/g, ""))
            ),
            type: 'bar',
            stack: 'rates',
            itemStyle: {
              color: colors.green.accent4
            }
          },
          {
            name: 'Rate Ask (Down)',
            data: lastTenMaturities.map(maturity => 
              -parseFloat(maturity.Expenses.replace(/,/g, ""))
            ),
            type: 'bar',
            stack: 'rates',
            itemStyle: {
              color: "#ED2B2A"
            }
          }
        ]
      };
    },
    // lastMaturities() {
    //   if(this.maturities.length <= 0) return []
    //   const today = new Date()
    //   const filteredMaturities = this.maturities.filter((maturity) => {
    //     const maturityDate = new Date(maturity.maturity)
    //     return maturityDate <= today
    //   })
    //   const sortedMaturities = filteredMaturities.sort((a, b) => {
    //     return new Date(b.maturity) - new Date(a.maturity)
    //   })
    //   const refactoredMaturities = sortedMaturities.map((item) => {
    //     const formattedMaturity = item.maturity.replace(/(\d{4})-(\d{2})-(\d{2})/, "$1-$3-$2")
    //     return { ...item, maturity: formattedMaturity }
    //   })
    //   return refactoredMaturities.slice(0, 10)
    // },
  },
  mounted () {
    this.maturitiesCsv = undefined
    this.refreshMaturities()
  },
  methods: {
    ...mapActions(useCashflowStore, [
      "saveMaturities",
      "getMaturities",
      "downloadMaturities"
    ]),
    importMaturities () {
      const file = this.maturitiesCsv
      if (!file) {
        this.snackbarError = true
        this.errorMsg = "Selecione o arquivo que deseja importar"
        this.dialog = false
        return
      }
      this.loadingPut = true
      const payload = {
        file: file
      }
      this.saveMaturities(payload)
        .then(() => {
          this.refreshMaturities()
          this.snackbarSuccess = true
          this.uploadSuccess = true
        })
        .catch ((error) => {
          this.dialog = false
          this.snackbarError = true
          if (error.response) {
            const errorMsg = error.response.data.detail
            this.errorMsg = errorMsg
          }
          else {
            this.errorMsg = "Error importing file."
          }
          console.log(error)
        })
        .finally(() => {
          this.loadingPut = false
        })
    },
    refreshMaturities () {
      this.loadingGet = true
      this.getMaturities()
        .then((response) => {
          const data = response.data
          this.maturities = data.data
        })
        .catch((error) => {
          if (error.response.status === 404) {
            // const errorMsg = error.response.data.description
            // this.errorMsg = errorMsg
            return
          }
          else {
            this.snackbarError = true
            this.errorMsg = "Erro ao recarregar maturidades."
          }
        })
        .finally(() => {
          this.loadingGet = false
        })
    },
    changeFile (file) {
      this.maturitiesCsv = file
    },
    exportCurrencyMaturities () {
      this.loadingDownload = true
      this.downloadMaturities()
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/csv" })
          const link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)
          link.download = "maturities.csv"
          link.click()
        })
        .catch((error) => {
          console.log(error)
          if (!error) {
            return
          }
          this.snackbarError = true
          if (error.response) {
            this.errorMsg = error.response.data
          }
          else {
            this.errorMsg = "Erro ao exportar arquivo."
          }
          console.log(error)
        })
        .finally(() => {
          this.loadingDownload = false
        })
    },
    showDialog () {
      this.dialog = true
    },
  }
}
</script>

<style scoped>
.chart {
  max-width: 1940px;
  min-width: 100px;
  /* width: 1940px; */
  width: 100%;
  min-height: 100px;
  max-height: 3000px;
  height: 100%;
}
</style>